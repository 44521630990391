import React, { useState } from 'react';
import { auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// reactstrap components
import avatar from 'assets/img/faces/ayo-ogunseinde-2.jpg';
import { NavLink } from 'react-router-dom';
import { Nav, Collapse, Button } from 'reactstrap';

const UserMenu = () => {
	const [openAvatar, setOpenAvatar] = useState(false);
	const [user, loading, error] = useAuthState(auth);

	const logout = () => {
		auth.signOut();
	};

	// console.log(user);
	return (
		<div className='user'>
			<div className='photo'>
				<img src={avatar} alt='Avatar' />
			</div>
			<div className='info'>
				<a
					href='#pablo'
					data-toggle='collapse'
					aria-expanded={openAvatar}
					onClick={() => setOpenAvatar(!openAvatar)}
				>
					<span>
						{user ? user.email : 'Not Logged In'}
						<b className='caret' />
					</span>
				</a>
				<Collapse isOpen={openAvatar}>
					<ul className='nav'>
						<li>
							<NavLink to='/admin/user-profile' activeClassName=''>
								<span className='sidebar-mini-icon'>MP</span>
								<span className='sidebar-normal'>My Profile</span>
							</NavLink>
						</li>
						<li>
							<Button color='danger' block onClick={logout}>
								Log Out
							</Button>
						</li>
					</ul>
				</Collapse>
			</div>
		</div>
	);
};

export default UserMenu;
