/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Spinner,
	UncontrolledAlert,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
import { auth } from 'config/firebase';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';

const Register = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [createUserWithEmailAndPassword, user, loading, error] =
		useCreateUserWithEmailAndPassword(auth);
	const history = useHistory();

	useEffect(() => {
		document.body.classList.toggle('register-page');
	}, []);

	if (user) {
		history.push('/');
	}
	return (
		<div className='register-page'>
			<Container>
				<Row>
					<Col className='ml-auto' lg='5' md='5'>
						<div className='info-area info-horizontal mt-5'>
							<div className='icon icon-primary'>
								<i className='nc-icon nc-tv-2' />
							</div>
							<div className='description'>
								<h5 className='info-title'>Marketing Tagline</h5>
								<p className='description'></p>
							</div>
						</div>
						<div className='info-area info-horizontal'>
							<div className='icon icon-primary'>
								<i className='nc-icon nc-html5' />
							</div>
							<div className='description'>
								<h5 className='info-title'>Fully Coded in HTML5</h5>
							</div>
						</div>
						<div className='info-area info-horizontal'>
							<div className='icon icon-info'>
								<i className='nc-icon nc-atom' />
							</div>
							<div className='description'>
								<h5 className='info-title'>Built in React</h5>
							</div>
						</div>
					</Col>
					<Col className='mr-auto' lg='4' md='6'>
						<Card className='card-signup text-center'>
							<CardHeader>
								<CardTitle tag='h4'>Register</CardTitle>
								<div className='social'>
									<Button className='btn-icon btn-round' color='twitter'>
										<i className='fa fa-twitter' />
									</Button>
									<Button className='btn-icon btn-round' color='dribbble'>
										<i className='fa fa-dribbble' />
									</Button>
									<Button className='btn-icon btn-round' color='facebook'>
										<i className='fa fa-facebook-f' />
									</Button>
									<p className='card-description'>or be classical</p>
								</div>
							</CardHeader>
							<CardBody>
								<Form action='' className='form' method=''>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='nc-icon nc-email-85' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder='Email...'
											type='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</InputGroup>
									<InputGroup>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText>
												<i className='nc-icon nc-key-25' />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder='Password.'
											type='password'
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</InputGroup>
									{error ? (
										<UncontrolledAlert color='danger'>
											{error.message}
										</UncontrolledAlert>
									) : null}
									<FormGroup check className='text-left'>
										<Label check>
											<Input defaultChecked type='checkbox' />
											<span className='form-check-sign' />I agree to the{' '}
											<a href='#pablo' onClick={(e) => e.preventDefault()}>
												terms and conditions
											</a>
											.
										</Label>
									</FormGroup>
								</Form>
							</CardBody>
							<CardFooter>
								<Button
									className='btn-round'
									color='info'
									href='#pablo'
									onClick={() =>
										createUserWithEmailAndPassword(email, password)
									}
								>
									{loading ? <Spinner /> : 'Get Started'}
								</Button>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
			<div
				className='full-page-background'
				style={{
					backgroundImage: `url(${require('assets/img/bg/register1.jpg')})`,
				}}
			/>
		</div>
	);
};

export default Register;
