/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/paper-dashboard.min.css';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const hist = createBrowserHistory();

const PrivateRoute = ({ user, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				user ? rest.render(props) : <Redirect to='/auth/login' />
			}
		/>
	);
};

const App = () => {
	const [user, userLoading, userError] = useAuthState(auth);

	if (userLoading) {
		return <div>Loading</div>;
	}
	return (
		<Router history={hist}>
			<Switch>
				<Route path='/auth' render={(props) => <AuthLayout {...props} />} />
				<PrivateRoute
					user={user}
					path='/admin'
					render={(props) => <AdminLayout {...props} />}
				/>
				<Redirect to='/admin/dashboard' />
			</Switch>
		</Router>
	);
};

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
