import React, { useState, useEffect, useMemo } from 'react';

import {
	Badge,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	CardText,
	Label,
	FormGroup,
	Input,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
	Modal,
	Form,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
const Hotspot = ({ props }) => {
	const { state } = useLocation();

	useEffect(() => {
		console.log(state);
	}, []);
	return (
		<>
			<div className='content'></div>
		</>
	);
};

export default Hotspot;
