/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
import { auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
	Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

const userLogin = (email, password) => {
	auth.signInWithEmailAndPassword(email, password);
};
const userlogout = () => {
	auth.signOut();
};

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [user, loading, error] = useAuthState(auth);
	const history = useHistory();

	if (user) {
		history.push('/');
	}
	return (
		<div className='login-page'>
			<Container>
				<Row>
					<Col className='ml-auto mr-auto' lg='4' md='6'>
						<Form action='' className='form' method=''>
							<Card className='card-login'>
								{user ? (
									<>
										<CardHeader>
											<CardHeader>
												<h3 className='header text-center'>{user.email}</h3>
											</CardHeader>
										</CardHeader>
										<CardFooter>
											<Button
												block
												className='btn-round mb-3'
												color='warning'
												href='#pablo'
												onClick={() => userlogout()}
											>
												Log Out
											</Button>
										</CardFooter>
									</>
								) : (
									<>
										<CardHeader>
											<CardHeader>
												<h3 className='header text-center'>Login</h3>
											</CardHeader>
										</CardHeader>
										<CardBody>
											<InputGroup>
												<InputGroupAddon addonType='prepend'>
													<InputGroupText>
														<i className='nc-icon nc-single-02' />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder='Email'
													type='text'
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType='prepend'>
													<InputGroupText>
														<i className='nc-icon nc-key-25' />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder='Password'
													type='password'
													autoComplete='off'
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</InputGroup>
											{error ? (
												<div>
													<p>Error: {error.message}</p>
												</div>
											) : null}
											<br />
											<FormGroup>
												<FormGroup check>
													<Label check>
														<Input
															defaultChecked
															defaultValue=''
															type='checkbox'
														/>
														<span className='form-check-sign' />
														Subscribe to newsletter
													</Label>
												</FormGroup>
											</FormGroup>
										</CardBody>
										<CardFooter>
											<Button
												block
												className='btn-round mb-3'
												color='warning'
												href='#pablo'
												onClick={() => userLogin(email, password)}
											>
												Get Started
											</Button>
										</CardFooter>
									</>
								)}
							</Card>
						</Form>
					</Col>
				</Row>
			</Container>
			<div
				className='full-page-background'
				style={{
					backgroundImage: `url(${require('assets/img/bg/login1.jpg')})`,
				}}
			/>
		</div>
	);
};

export default Login;
