import React, { useState, useMemo } from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Modal,
} from 'reactstrap';
// react plugin for creating notifications over the dashboard
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTable from 'components/ReactTable/ReactTable';
import axios from 'axios';
import { db, auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PulseLoader from 'react-spinners/PulseLoader';
// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Multiselect } from 'multiselect-react-dropdown';

import Hotspots from '@helium/http/build/resources/Hotspots';

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
	if (value.length >= length) {
		return true;
	}
	return false;
};

const Hosts = () => {
	const [showModal, setShowModal] = useState(false);
	//Form Fields
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [address, setAddress] = useState('');
	const [address2, setAddress2] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [phone, setPhone] = useState('');
	const [split, setSplit] = useState('');
	const [details, setDetails] = useState('');
	const [hostHotspots, setHostHotspots] = useState([]);
	const [hostId, setHostId] = useState('');
	const [editMode, setEditMode] = useState(false);
	//Alerts
	const [alertWindow, setAlertWindow] = useState(null);
	//Data
	const [user, userLoading, userError] = useAuthState(auth);
	const [errorMessage, setErrorMessage] = useState(null);
	const [hosts, hostsLoading, hostsError] = useCollectionData(
		db.collection('users').doc(user?.uid).collection('hosts'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);
	const [hotspots, hotspotsLoading, hotspotsError] = useCollectionData(
		db.collection('users').doc(user?.uid).collection('hotspots'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);

	const addNewHost = async () => {
		console.log('adding: ', firstName, lastName);

		if (!editMode) {
			db.collection(`users/${user.uid}/hosts`)
				.add({
					firstName: firstName,
					lastName: lastName,
					fullName: `${firstName} ${lastName}`,
					address: address,
					address2: address2,
					split: split,
					email: email,
					city: city,
					state: state,
					zip: zip,
					phone: phone,
					details: details,
					hotspots: hostHotspots,
				})
				.then((response) => {
					notify(`Added Host ${firstName + ' ' + lastName} to your account.`);
					console.log('Added a Host: ', firstName, lastName);
					db.collection(`users/${user.uid}/hosts`)
						.doc(`${response.id}`)
						.set({ id: response.id }, { merge: true });
					if (hostHotspots !== undefined || hostHotspots.length !== 0) {
						hostHotspots.map((item) => {
							db.collection(`users/${user.uid}/hotspots`)
								.doc(`${item.id}`)
								.set(
									{ host: { id: response.id, split: split } },
									{ merge: true }
								);
						});
					}
					clearVariables();
					toggleModal();
				});
		} else {
			db.collection(`users/${user.uid}/hosts`)
				.doc(hostId)
				.set({
					firstName: firstName,
					lastName: lastName,
					fullName: `${firstName} ${lastName}`,
					address: address,
					address2: address2,
					split: split,
					email: email,
					city: city,
					state: state,
					zip: zip,
					phone: phone,
					details: details,
					hotspots: hostHotspots,
					id: hostId,
				})
				.then((response) => {
					notify(`Saved changes to host: ${firstName + ' ' + lastName}`);
					console.log('Editing a Host: ', firstName, lastName);
					if (hostHotspots !== undefined || hostHotspots.length !== 0) {
						hostHotspots.map((item) => {
							db.collection(`users/${user.uid}/hotspots`)
								.doc(`${item.id}`)
								.set({ host: { id: hostId, split: split } }, { merge: true });
						});
					}
					clearVariables();
					toggleModal();
				});
		}
	};

	const clearVariables = () => {
		setFirstName('');
		setLastName('');
		setAddress('');
		setAddress2('');
		setEmail('');
		setCity('');
		setState('');
		setZip('');
		setPhone('');
		setDetails('');
		setHostHotspots([]);
		setSplit('');
		setHostId('');
		setEditMode(false);
	};

	const deleteWithConfirmMessage = (id) => {
		setAlertWindow(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title='Are you sure?'
				onConfirm={() => successDelete(id)}
				onCancel={() => cancelDelete()}
				confirmBtnBsStyle='info'
				cancelBtnBsStyle='danger'
				confirmBtnText='Yes, delete it!'
				cancelBtnText='Cancel'
				showCancel
				btnSize=''
			>
				You will not be able to recover this record!
			</ReactBSAlert>
		);
	};
	const successDelete = (id) => {
		deleteHost(id);
		setAlertWindow(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title='Deleted!'
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle='info'
				btnSize=''
			>
				Your record has been deleted.
			</ReactBSAlert>
		);
	};
	const cancelDelete = () => {
		setAlertWindow(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title='Cancelled'
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle='info'
				btnSize=''
			>
				Your record is safe
			</ReactBSAlert>
		);
	};

	const deleteHost = (id) => {
		db.collection(`users/${user.uid}/hosts`)
			.doc(`${id}`)
			.delete()
			.then((response) => {
				console.log('Removed Hotspot: ', id);
			});
		if (hostHotspots !== undefined || hostHotspots.length !== 0) {
			hostHotspots.map((item) => {
				db.collection(`users/${user.uid}/hotspots`)
					.doc(`${item.id}`)
					.set({ host: null }, { merge: true });
			});
		}
	};

	const hideAlert = () => {
		setAlertWindow(null);
	};

	const columns = useMemo(() => [
		{
			Header: 'Name',
			accessor: 'fullName', // accessor is the "key" in the data
		},
		{
			Header: 'City',
			accessor: 'city',
		},
		{
			Header: 'State',
			accessor: 'state',
		},
		{
			Header: '% Split',
			accessor: 'split',
		},
		{
			Header: '# Hotspots',
			accessor: 'hotspots',
			Cell: (props) => (props.value ? props.value.length : '-'),
		},
		{
			Header: 'Actions',
			accessor: 'actions',
			Cell: (props) => {
				const rowIdx = props.row;
				return (
					<div className='actions-right'>
						<Button
							onClick={() => loadModel(rowIdx)}
							color='warning'
							size='sm'
							className='btn-icon btn-link edit'
						>
							<i className='fa fa-edit' />
						</Button>{' '}
						<Button
							onClick={() => {
								deleteWithConfirmMessage(rowIdx.original.id);
							}}
							color='danger'
							size='sm'
							className='btn-icon btn-link remove'
						>
							<i className='fa fa-times' />
						</Button>
					</div>
				);
			},
		},
	]);

	const notify = (message) => toast.warn(message);

	const loadModel = (id) => {
		setEditMode(true);
		if (id.original.id) {
			setFirstName(id.original.firstName);
			setLastName(id.original.lastName);
			setAddress(id.original.address);
			setAddress2(id.original.address2);
			setCity(id.original.city);
			setState(id.original.state);
			setZip(id.original.zip);
			setEmail(id.original?.email);
			setDetails(id.original.details);
			setHostHotspots(id.original.hotspots);
			setPhone(id.original.phone);
			setSplit(id.original.split);
			setHostId(id.original.id);
		}
		setShowModal(!showModal);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const onSelect = (selectedList, selectedItem) => {
		setHostHotspots(selectedList);
	};

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{alertWindow}
			<div className='content'>
				<Row>
					<Col md='12'>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>
									My Hosts
									<div className='float-right'>
										<Button size='sm' color='primary' onClick={toggleModal}>
											Add Host
										</Button>
									</div>
								</CardTitle>
							</CardHeader>
							<CardBody>
								{!hostsLoading && hosts ? (
									<ReactTable columns={columns} data={hosts} />
								) : (
									<PulseLoader
										color={'primary'}
										loading={hostsLoading}
										size={150}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				{/* Start Modal */}

				<Modal isOpen={showModal} toggle={toggleModal}>
					<div className='modal-header justify-content-center'>
						<button
							aria-label='Close'
							className='close'
							data-dismiss='modal'
							type='button'
							onClick={toggleModal}
						>
							<i className='nc-icon nc-simple-remove' />
						</button>
						<h4 className='title title-up'>Host Details</h4>
					</div>
					<div className='modal-body'>
						<Form>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label>First Name</Label>
										<Input
											type='text'
											name='name'
											id='firstName'
											placeholder={firstName === '' ? 'First Name' : firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>Last Name</Label>
										<Input
											type='text'
											name='name'
											id='lastName'
											placeholder={lastName === '' ? 'Last Name' : lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label>Email</Label>
								<Input
									type='email'
									name='email'
									id='exampleEmail'
									value={email === '' ? '' : email}
									placeholder={email === '' ? 'email@example.com' : email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormGroup>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label>Host Recieves</Label>
										<Input
											type='number'
											name='hostrewards'
											id='hostrewards'
											placeholder={split === '' ? '% to host' : `${split} %`}
											onChange={(e) => setSplit(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>I Recieve</Label>
										<Input
											type='number'
											name='myrewards'
											id='myrewards'
											placeholder={
												split === '' ? '% I keep' : `${100 - split} %`
											}
											onChange={(e) => setLastName(e.target.value)}
											disabled
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label for='exampleSelectMulti'>Select Host Hotspot(s)</Label>
								<Multiselect
									options={
										!hotspotsLoading
											? hotspots.map((item) => ({
													id: item.id,
													name: item.name,
											  }))
											: {}
									} // Options to display in the dropdown
									loading={hotspotsLoading}
									selectedValues={hostHotspots} // Preselected value to persist in dropdown
									onSelect={onSelect} // Function will trigger on select event
									onRemove={onSelect} // Function will trigger on remove event
									displayValue='name' // Property name to display in the dropdown options
								/>
							</FormGroup>

							<FormGroup>
								<Label>Address</Label>
								<Input
									type='text'
									name='address'
									id='exampleAddress'
									placeholder={address === '' ? '123 Address St' : address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Address 2</Label>
								<Input
									type='text'
									name='address2'
									id='exampleAddress2'
									placeholder={
										address2 === '' ? 'Apartment, studio, or floor' : address2
									}
									onChange={(e) => setAddress2(e.target.value)}
								/>
							</FormGroup>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label>City</Label>
										<Input
											type='text'
											name='city'
											id='exampleCity'
											placeholder={city === '' ? 'City' : city}
											onChange={(e) => setCity(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col md={2}>
									<FormGroup>
										<Label>State</Label>
										<Input
											type='text'
											name='state'
											id='exampleState'
											placeholder={state === '' ? 'ST' : state}
											onChange={(e) => setState(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label>Zip</Label>
										<Input
											type='text'
											name='zip'
											id='exampleZip'
											placeholder={zip === '' ? '123456' : zip}
											onChange={(e) => setZip(e.target.value)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label>Additional Details</Label>
								<Input
									type='textarea'
									name='details'
									id='details'
									placeholder={
										details === ''
											? 'Notes about the hotspot or host setup...'
											: details
									}
									onChange={(e) => setDetails(e.target.value)}
								/>
							</FormGroup>
						</Form>
					</div>
					<div className='modal-footer'>
						<div className='left-side'>
							<Button
								className='btn-link'
								color='default'
								data-dismiss='modal'
								type='button'
								onClick={toggleModal}
							>
								Cancel
							</Button>
						</div>
						<div className='divider' />
						<div className='right-side'>
							<Button
								className='btn-link'
								color='success'
								type='button'
								onClick={addNewHost}
							>
								Save Host
							</Button>
						</div>
					</div>
				</Modal>
				{/* end notice modal */}
			</div>
		</>
	);
};

export default Hosts;
