/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useMemo } from 'react';
// react plugin used to create charts
import { Line, Bar, Doughnut } from 'react-chartjs-2';
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap';
import ReactTable from 'components/ReactTable/ReactTable';
import axios from 'axios';
import { db, auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PulseLoader from 'react-spinners/PulseLoader';
import dayjs from 'dayjs';
// react component used to create sweet alerts
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { HiTrendingUp, HiTrendingDown, HiCurrencyDollar } from 'react-icons/hi';

import { chartExample10 } from 'variables/charts.js';
// reactstrap components
import {
	Badge,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	CardText,
	Label,
	FormGroup,
	Input,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
	Modal,
	Form,
} from 'reactstrap';
import Switch from 'react-bootstrap-switch';
import Client from '@helium/http';

const client = new Client();
const MAX = 100000;

const Dashboard = () => {
	const [heliumPrice, setHeliumPrice] = useState(null);
	const [alertWindow, setAlertWindow] = useState(null);
	//EDIT HOST DETAILS
	const [showModal, setShowModal] = useState(false);
	const [editHostName, setEditHostName] = useState('');
	const [editHostAddress, setEditHostAddress] = useState('');
	const [editHostSplit, setEditHostSplit] = useState('');
	const [editHostDetails, setEditHostDetails] = useState('');

	//CHART SHIT
	const [totalToday, setTotalToday] = useState(null);
	const [alltimeTotal, setAlltimeTotal] = useState(null);
	const [oneDayTotal, setOneDayTotal] = useState(null);
	const [yesterdayTotal, setYesterdayTotal] = useState(null);
	const [yesterdayDelta, setYesterdayDelta] = useState(null);
	const [sevenDayTotal, setSevenDayTotal] = useState(null);
	const [sevenDayDelta, setSevenDayDelta] = useState(null);
	const [thirtyDayTotal, setThirtyDayTotal] = useState(null);
	const [thirtyDayDelta, setThirtyDayDelta] = useState(null);
	const [thirtyDayAdjusted, setThirtyDayAdjusted] = useState(null);
	const [thirtyDayStandard, setThirtyDayStandard] = useState(null);
	const [adjusted, setAdjusted] = useState(false);

	//TABLE DETAILS
	const [tableData, setTableData] = useState(null);

	//loading indicators
	const [lifetimeLoading, setLifetimeLoading] = useState(false);
	const [thirtyDayLoading, setThirtyDayLoading] = useState(false);
	const [detailsLoading, setDetailsLoading] = useState(false);

	//30 day chart data
	const [thirtyDayChartData, setThirtyDayChartData] = useState(null);
	const [thirtyDayChartLabels, setThirtyDayChartLabels] = useState(null);

	//FIREBASE SHIT
	const [user, userLoading, userError] = useAuthState(auth);
	const [hosts, hostsLoading, hostsError] = useCollectionData(
		db.collection('users').doc(user?.uid).collection('hosts'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);
	const [value, dataLoading, dataError] = useCollectionData(
		db.collection('users').doc(user?.uid).collection('hotspots'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);

	const getHeliumPrice = async () => {
		setHeliumPrice(
			(await client.oracle.getCurrentPrice()).price.floatBalance.toFixed(2)
		);
	};
	const fetchHotspot = async (address) => {
		const client = new Client();
		const hotspot = await client.hotspots.get(address);
		return JSON.parse(JSON.stringify(hotspot));
	};
	const getHotspotRewardsBuckets = async (address, numBack, bucketType) => {
		const list = await client.hotspot(address).rewards.sum.list({
			minTime: `-${numBack} ${bucketType}`,
			maxTime: new Date(),
			bucket: bucketType,
		});
		const rewards = await list.take(MAX);
		return rewards;
	};

	const getHotspotRewardsSum = async (address, numDaysBack) => {
		const initialDate = new Date();
		const endDate = new Date();
		endDate.setDate(initialDate.getDate() - numDaysBack);
		return client.hotspot(address).rewards.sum.get(endDate, initialDate);
	};

	const getHotspotHistoricalRewardsSum = async (
		address,
		numDaysBack,
		toDaysBack
	) => {
		const initialDate = new Date();
		const endDate = new Date();
		initialDate.setDate(initialDate.getDate() - numDaysBack);
		endDate.setDate(initialDate.getDate() - toDaysBack);
		return client.hotspot(address).rewards.sum.get(endDate, initialDate);
	};

	const deleteWithConfirmMessage = (id) => {
		setAlertWindow(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title='Are you sure?'
				onConfirm={() => successDelete(id)}
				onCancel={() => cancelDelete()}
				confirmBtnBsStyle='info'
				cancelBtnBsStyle='danger'
				confirmBtnText='Yes, delete it!'
				cancelBtnText='Cancel'
				showCancel
				btnSize=''
			>
				You will not be able to recover this record!
			</ReactBSAlert>
		);
	};
	const successDelete = (id) => {
		deleteHotspot(id);
		setAlertWindow(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title='Deleted!'
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle='info'
				btnSize=''
			>
				Your record has been deleted.
			</ReactBSAlert>
		);
	};
	const cancelDelete = () => {
		setAlertWindow(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title='Cancelled'
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle='info'
				btnSize=''
			>
				Your record is safe
			</ReactBSAlert>
		);
	};

	const hideAlert = () => {
		setAlertWindow(null);
	};

	const colorStatus = (status) => {
		if (status === 'online') {
			return (
				<Badge color='success' pill>
					ONLINE
				</Badge>
			);
		} else {
			return (
				<Badge color='danger' pill>
					OFFLINE
				</Badge>
			);
		}
	};

	const colorOffline = (num) => {
		if (num === 0) {
			return (
				<Badge color='success' pill>
					0 👍
				</Badge>
			);
		} else {
			return (
				<Badge color='danger' pill>
					{num} ⚠️
				</Badge>
			);
		}
	};

	const colorScale = (perc) => {
		if (perc < 0.25) {
			return (
				<Badge color='danger' pill>
					{perc}
				</Badge>
			);
		} else if (perc < 0.5) {
			return (
				<Badge color='warning' pill>
					{perc}
				</Badge>
			);
		} else if (perc < 0.75) {
			return (
				<Badge color='info' pill>
					{perc}
				</Badge>
			);
		} else {
			return (
				<Badge color='success' pill>
					{perc}
				</Badge>
			);
		}
	};

	const calcUptime = (pingArr) => {
		if (pingArr.length > 0) {
			let online = pingArr.filter((obj) => obj.status === 'online').length;

			return (
				<Badge color='default'>
					{((online / pingArr.length) * 100).toFixed(0)} %
				</Badge>
			);
		} else {
			return <Badge color='info'>N/A</Badge>;
		}
	};

	const columns = useMemo(() => [
		{
			Header: 'Name',
			accessor: 'name', // accessor is the "key" in the data
		},
		{
			Header: 'City',
			accessor: 'geocode.shortCity',
		},
		{
			Header: 'State',
			accessor: 'geocode.shortState',
		},
		{
			Header: `Host`,
			accessor: 'hostName',
		},
		{
			Header: `Split`,
			accessor: 'split',
		},
		{
			Header: `24hr Rewards`,
			accessor: 'rewards',
		},
		{
			Header: `Status`,
			accessor: 'status.online',
			style: {
				textAlign: 'center',
			},
			// provide custom function to format props
			Cell: (props) => (
				<div
					style={{
						textAlign: 'center',
					}}
				>
					{' '}
					{colorStatus(props.value)}{' '}
				</div>
			),
		},
		{
			Header: `Reward Scale`,
			accessor: 'rewardScale',
			style: {
				textAlign: 'center',
			},
			// provide custom function to format props
			Cell: (props) => (
				<div
					style={{
						textAlign: 'center',
					}}
				>
					{props.value ? (
						colorScale(props.value.toFixed(2))
					) : (
						<Badge color='muted' pill>
							-
						</Badge>
					)}{' '}
				</div>
			),
		},
		{
			Header: `Uptime`,
			accessor: 'pingStatus',
			style: {
				textAlign: 'center',
			},
			// provide custom function to format props
			Cell: (props) => (
				<div
					style={{
						textAlign: 'center',
					}}
				>
					{props.value ? (
						calcUptime(props.value)
					) : (
						<Badge color='muted' pill>
							-
						</Badge>
					)}{' '}
				</div>
			),
		},
		{
			Header: 'Actions',
			accessor: 'actions',
			Cell: (props) => {
				const rowIdx = props.row;
				return (
					<div className='actions-right'>
						<Button
							onClick={() => loadModel(rowIdx)}
							color='warning'
							size='sm'
							className='btn-icon btn-link edit'
						>
							<i className='fa fa-edit' />
						</Button>{' '}
						<Button
							onClick={() => {
								deleteWithConfirmMessage(rowIdx.original.address);
								console.log(rowIdx);
							}}
							color='danger'
							size='sm'
							className='btn-icon btn-link remove'
						>
							<i className='fa fa-times' />
						</Button>
					</div>
				);
			},
		},
	]);

	const loadModel = (id) => {
		if (id.original.host) {
			setEditHostName(id.original.host.name);
			setEditHostAddress(id.original.host.address);
		}
		setShowModal(!showModal);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const deleteHotspot = (id) => {
		db.collection(`users/${user.uid}/hotspots`)
			.doc(`${id}`)
			.delete()
			.then((response) => {
				console.log('Removed Hotspot: ', id);
			});
	};

	const calcPercentageChange = (num, old) => {
		if (num === null || old === null) {
			return '-';
		}
		num = parseFloat(num);
		old = parseFloat(old);
		let change;
		if (old !== 0) {
			if (num !== 0) {
				change = Math.abs(((old - num) / num) * 100);
			} else {
				change = Math.abs(old * 100);
			}
		} else {
			change = num * 100;
		}

		return old.toFixed(2) - num.toFixed(2) > 0 ? (
			<p>
				{change.toFixed(0)}% <HiTrendingDown color='red' />
			</p>
		) : (
			<p>
				{change.toFixed(0)}% <HiTrendingUp color='green' />
			</p>
		);
	};

	const setDashboardAdjusted = () => {
		if (adjusted === true) {
			// console.log('Fuuuuuck');
			// console.log('adjusted', thirtyDayAdjusted);
			// console.log('regular', thirtyDayStandard);

			let standardThirtyDay = [...thirtyDayStandard];

			setOneDayTotal(standardThirtyDay[0].toFixed(2));
			setYesterdayTotal(standardThirtyDay[1].toFixed(2));
			setYesterdayDelta(standardThirtyDay[2].toFixed(2));
			setThirtyDayTotal(
				standardThirtyDay.reduce((a, b) => a + b, 0).toFixed(2)
			);
			setSevenDayTotal(
				standardThirtyDay
					.slice(0, 6)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);
			setSevenDayDelta(
				standardThirtyDay
					.slice(7, 13)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);

			setThirtyDayChartData([...standardThirtyDay].reverse());
			setAdjusted(!adjusted);
		} else {
			// console.log('what the fuck');
			// console.log('adjusted', thirtyDayAdjusted);
			// console.log('regular', thirtyDayStandard);

			let adjustedThirtyDay = [...thirtyDayAdjusted];

			setOneDayTotal(adjustedThirtyDay[0].toFixed(2));
			setYesterdayTotal(adjustedThirtyDay[1].toFixed(2));
			setYesterdayDelta(adjustedThirtyDay[2].toFixed(2));
			setThirtyDayTotal(
				adjustedThirtyDay.reduce((a, b) => a + b, 0).toFixed(2)
			);
			setSevenDayTotal(
				adjustedThirtyDay
					.slice(0, 6)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);
			setSevenDayDelta(
				adjustedThirtyDay
					.slice(7, 13)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);

			setThirtyDayChartData([...adjustedThirtyDay].reverse());

			setAdjusted(!adjusted);
		}
	};

	useEffect(() => {
		getHeliumPrice();

		async function populateHotspots() {
			setThirtyDayLoading(true);

			let thirtyDayLabels = [];
			let thirtyDayTotals = [];
			let thirtyDayAdjustedTotals = [];

			const tot = await Promise.all(
				value.map(async (item) => {
					const res = await getHotspotRewardsBuckets(`${item.id}`, 30, 'day');

					for (var i = 0; i < res.length; i++) {
						thirtyDayLabels[i] = dayjs(res[i].timestamp).format('MMM DD');
						thirtyDayTotals[i] = thirtyDayTotals[i]
							? thirtyDayTotals[i] + res[i].total
							: res[i].total;

						if (item.host && item.host.split && item.host.split > 0) {
							thirtyDayAdjustedTotals[i] = thirtyDayAdjustedTotals[i]
								? thirtyDayAdjustedTotals[i] +
								  res[i].total * (parseFloat(item.host.split) / 100)
								: res[i].total * (parseFloat(item.host.split) / 100);
						} else {
							thirtyDayAdjustedTotals[i] = thirtyDayAdjustedTotals[i]
								? thirtyDayAdjustedTotals[i] + res[i].total
								: res[i].total;
						}
					}
				})
			);

			setThirtyDayAdjusted(thirtyDayAdjustedTotals);
			setThirtyDayStandard(thirtyDayTotals);

			setOneDayTotal(thirtyDayTotals[0].toFixed(2));
			setYesterdayTotal(thirtyDayTotals[1].toFixed(2));
			setYesterdayDelta(thirtyDayTotals[2].toFixed(2));
			setThirtyDayTotal(thirtyDayTotals.reduce((a, b) => a + b, 0).toFixed(2));
			setSevenDayTotal(
				thirtyDayTotals
					.slice(0, 6)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);
			setSevenDayDelta(
				thirtyDayTotals
					.slice(7, 13)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			);
			setThirtyDayChartData([...thirtyDayTotals].reverse());
			setThirtyDayChartLabels([...thirtyDayLabels].reverse());

			setThirtyDayLoading(false);
		}

		async function getLifetimeRewards() {
			setLifetimeLoading(true);
			let tot = [];
			let comp = [];
			tot = await Promise.all(
				value.map(async (item) => {
					const t = await getHotspotRewardsSum(`${item.id}`, 500);
					return t.total;
				})
			);

			comp = await Promise.all(
				value.map(async (item) => {
					const t = await getHotspotHistoricalRewardsSum(`${item.id}`, 31, 60);
					return t.total;
				})
			);

			setThirtyDayDelta(comp.reduce((a, b) => a + b, 0).toFixed(2));
			setAlltimeTotal(tot.reduce((a, b) => a + b, 0).toFixed(2));

			setLifetimeLoading(false);
		}

		async function getHotspotDetails() {
			setDetailsLoading(true);
			let tot = [];

			tot = await Promise.all(
				value.map(async (item) => {
					const t = await fetchHotspot(`${item.id}`);
					const rewards = await getHotspotRewardsSum(`${item.id}`, 1);

					t.rewards = rewards.total.toFixed(2);
					if (item.pingStatus) {
						t.pingStatus = item.pingStatus;
					}
					if (item.host) {
						let h = hosts.find((o) => o.id === item.host.id);

						if (h !== undefined && h.split) {
							t.hostName = h.fullName;
							t.split = h.split;
						}
					}

					return t;
				})
			);
			setTableData(tot);

			setDetailsLoading(false);
		}
		if (value && value.length > 0) {
			getLifetimeRewards();
			populateHotspots();
			getHotspotDetails();
		}
	}, [value]);

	return (
		<>
			<div className='content'>
				{alertWindow}
				<Row>
					{/* HNT PRICE */}
					<Col lg='3' md='6' sm='6'>
						<Card className='card-stats'>
							<CardBody>
								<Row>
									<Col md='4' xs='5'>
										<div className='icon-big text-center icon-warning'>
											<HiCurrencyDollar color='mediumaquamarine' />
										</div>
									</Col>
									<Col md='8' xs='7'>
										<div className='numbers'>
											<p className='card-category'>HNT Price</p>
											<CardTitle tag='p'>
												$ {heliumPrice !== null ? `${heliumPrice}` : '-'}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className='stats'>
									<i className='fa fa-clock-o' />
									Current Price in USD
								</div>
							</CardFooter>
						</Card>
					</Col>
					{/* TOTAL HOTSPOTS */}
					<Col lg='3' md='6' sm='6'>
						<Card className='card-stats'>
							<CardBody>
								<Row>
									<Col md='4' xs='5'>
										<div className='icon-big text-center icon-warning'>
											<i className='nc-icon nc-vector text-warning' />
										</div>
									</Col>
									<Col md='8' xs='7'>
										<div className='numbers'>
											<p className='card-category'>Total Hotspots</p>
											<CardTitle tag='p'>
												{dataLoading ? (
													<PulseLoader
														color={'primary'}
														loading={dataLoading}
														size={15}
													/>
												) : (
													value.length
												)}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className='stats'>
									<i className='fa fa-refresh' />
									In your arsenal
								</div>
							</CardFooter>
						</Card>
					</Col>
					{/* OFFLINE HOTSPOTS */}
					<Col lg='3' md='6' sm='6'>
						<Card className='card-stats'>
							<CardBody>
								<Row>
									<Col md='4' xs='5'>
										<div className='icon-big text-center icon-warning'>
											<i className='nc-icon nc-globe text-danger' />
										</div>
									</Col>
									<Col md='8' xs='7'>
										<div className='numbers'>
											<p className='card-category'>Offline Hotspots</p>
											<CardTitle tag='p'>
												{detailsLoading || tableData === null ? (
													<PulseLoader />
												) : (
													colorOffline(
														tableData.filter(
															(o) => o.status.online === 'offline'
														).length
													)
												)}
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className='stats'>
									<i className='fa fa-clock-o' />
									---
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg='3' md='6' sm='6'>
						<Card className='card-stats'>
							<CardBody>
								<Row>
									<Col md='4' xs='5'>
										<div className='icon-big text-center icon-warning'>
											<i className='nc-icon nc-money-coins text-warning' />
										</div>
									</Col>
									<Col md='8' xs='7'>
										<div className='numbers'>
											<p className='card-category'>Lifetime Rewards</p>
											<CardTitle tag='p'>
												{lifetimeLoading ? <PulseLoader /> : alltimeTotal} HNT
											</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className='stats'>
									<Switch
										offColor='success'
										offText={<i className='nc-icon nc-simple-remove' />}
										onColor='success'
										onText={<i className='nc-icon nc-check-2' />}
										defaultValue={false}
										onChange={setDashboardAdjusted}
									/>{' '}
									Adjust Rewards
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md='6'>
						<Card className='card-chart'>
							<CardHeader>
								<CardTitle>30 Day Rewards</CardTitle>
								<p className='card-category'>Total for all my hotspots</p>
							</CardHeader>
							<CardBody>
								{thirtyDayLoading ? (
									<PulseLoader />
								) : (
									<Bar
										data={{
											labels: thirtyDayChartLabels,
											datasets: [
												{
													label: 'Rewards',
													borderColor: 'coral',
													fill: true,
													backgroundColor: 'coral',
													hoverBorderColor: '#fcc468',
													hoverColor: '#fcc468',
													borderWidth: 1,
													barPercentage: 1,
													data: thirtyDayChartData,
												},
											],
										}}
										options={chartExample10.options}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' md='6' sm='6'>
						<Card>
							<CardHeader>
								<CardText tag='div'>
									<CardTitle tag='h4'>Hotspot Stats</CardTitle>
									<p className='card-category'>
										HNT Rewards Across all hotspots
									</p>
								</CardText>
							</CardHeader>
							<CardBody className='table-responsive'>
								<Table className='table-hover'>
									<thead className='text-warning'>
										<tr>
											<th>Period</th>
											<th>Total</th>
											<th>Trend</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Today</td>
											<td>
												{' '}
												{lifetimeLoading ? <PulseLoader /> : oneDayTotal} HNT
											</td>
											<td>
												{lifetimeLoading ? (
													<PulseLoader />
												) : (
													calcPercentageChange(oneDayTotal, yesterdayTotal)
												)}
											</td>
										</tr>
										<tr>
											<td>Yesterday</td>
											<td>
												{' '}
												{lifetimeLoading ? <PulseLoader /> : yesterdayTotal} HNT
											</td>
											<td>
												{lifetimeLoading ? (
													<PulseLoader />
												) : (
													calcPercentageChange(yesterdayTotal, yesterdayDelta)
												)}
											</td>
										</tr>
										<tr>
											<td>7 Days</td>
											<td>
												{' '}
												{lifetimeLoading ? <PulseLoader /> : sevenDayTotal} HNT
											</td>
											<td>
												{lifetimeLoading ? (
													<PulseLoader />
												) : (
													calcPercentageChange(sevenDayTotal, sevenDayDelta)
												)}
											</td>
										</tr>
										<tr>
											<td>30 Days</td>
											<td>
												{' '}
												{lifetimeLoading ? <PulseLoader /> : thirtyDayTotal} HNT
											</td>
											<td>
												{lifetimeLoading ? (
													<PulseLoader />
												) : (
													calcPercentageChange(thirtyDayTotal, thirtyDayDelta)
												)}
											</td>
										</tr>
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>My Hotspots</CardTitle>
							</CardHeader>
							<CardBody>
								{!detailsLoading && tableData ? (
									<ReactTable columns={columns} data={tableData} />
								) : (
									<PulseLoader
										color={'primary'}
										loading={dataLoading}
										size={150}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row></Row>
				{/* Start Modal */}

				<Modal isOpen={showModal} toggle={toggleModal}>
					<div className='modal-header justify-content-center'>
						<button
							aria-label='Close'
							className='close'
							data-dismiss='modal'
							type='button'
							onClick={toggleModal}
						>
							<i className='nc-icon nc-simple-remove' />
						</button>
						<h4 className='title title-up'>Edit Hotspot Details</h4>
					</div>
					<div className='modal-body'>
						<Form>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label>Name</Label>
										<Input
											type='test'
											name='name'
											id='exampleName'
											placeholder={editHostName}
											onChange={(e) => setEditHostName(e.target.value)}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label for='exampleEmail'>Email</Label>
										<Input
											type='email'
											name='email'
											id='exampleEmail'
											placeholder='with a placeholder'
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label for='exampleAddress'>Address</Label>
								<Input
									type='text'
									name='address'
									id='exampleAddress'
									placeholder={editHostAddress}
									onChange={(e) => setEditHostAddress(e.target.value)}
								/>
							</FormGroup>
							<FormGroup>
								<Label for='exampleAddress2'>Address 2</Label>
								<Input
									type='text'
									name='address2'
									id='exampleAddress2'
									placeholder='Apartment, studio, or floor'
								/>
							</FormGroup>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<Label for='exampleCity'>City</Label>
										<Input type='text' name='city' id='exampleCity' />
									</FormGroup>
								</Col>
								<Col md={2}>
									<FormGroup>
										<Label for='exampleState'>State</Label>
										<Input type='text' name='state' id='exampleState' />
									</FormGroup>
								</Col>
								<Col md={4}>
									<FormGroup>
										<Label for='exampleZip'>Zip</Label>
										<Input type='text' name='zip' id='exampleZip' />
									</FormGroup>
								</Col>
							</Row>
						</Form>
					</div>
					<div className='modal-footer'>
						<div className='left-side'>
							<Button
								className='btn-link'
								color='default'
								data-dismiss='modal'
								type='button'
								onClick={toggleModal}
							>
								Never mind
							</Button>
						</div>
						<div className='divider' />
						<div className='right-side'>
							<Button className='btn-link' color='danger' type='button'>
								Delete
							</Button>
						</div>
					</div>
				</Modal>
				{/* end notice modal */}
			</div>
		</>
	);
};

export default Dashboard;
