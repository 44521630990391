import React, { useState, useMemo } from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
// react plugin for creating notifications over the dashboard
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTable from 'components/ReactTable/ReactTable';
import axios from 'axios';
import { db, auth } from 'config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import PulseLoader from 'react-spinners/PulseLoader';

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
	if (value.length >= length) {
		return true;
	}
	return false;
};

const AddHotspot = () => {
	const [hotspotId, setHotspotId] = useState('');

	const [validationStyle, setValidationStyle] = useState('');
	const [user, userLoading, userError] = useAuthState(auth);
	const [errorMessage, setErrorMessage] = useState(null);
	const [value, dataLoading, dataError] = useCollectionData(
		db.collection('users').doc(user?.uid).collection('hotspots'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);

	const addNewHotspot = async (hotspot) => {
		console.log('adding: ', hotspotId);
		axios
			.get(`https://api.helium.io/v1/hotspots/${hotspot}`)
			.then(function (response) {
				const spotName = response.data.data.name;

				db.collection(`users/${user.uid}/hotspots`)
					.doc(`${hotspot}`)
					.set({
						id: hotspot,
						name: response.data.data.name,
						data: response.data.data,
					})
					.then((response) => {
						notify(`Added ${spotName} to your account.`);
						console.log('Added a hotspot: ', hotspot);
					});
			})
			.catch(function (error) {
				notify(error.message);
				setErrorMessage(
					'There was an issue adding your hotspot, check the ID and try again.'
				);
			})
			.then(() => {
				setErrorMessage(null);
			});
	};

	const typeClick = () => {
		if (setHotspotId === '' || hotspotId.length < 30) {
			setValidationStyle('has-danger');
			notify('Invalid Hotspot ID');
		} else {
			addNewHotspot(hotspotId);
		}
	};

	const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'length':
				if (verifyLength(event.target.value, stateNameEqualTo)) {
					setValidationStyle('has-success');
				} else {
					setValidationStyle('has-danger');
				}
				break;
			default:
				break;
		}
		setHotspotId(event.target.value);
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name', // accessor is the "key" in the data
			},
			{
				Header: 'City',
				accessor: 'data.geocode.short_city',
			},
			{
				Header: 'State',
				accessor: 'data.geocode.short_state',
			},
		],
		[]
	);

	const notify = (message) => toast.warn(message);

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className='content'>
				<Row>
					<Col md='12'>
						<Form className='form-horizontal' id='TypeValidation'>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Add a new Hotspot</CardTitle>
								</CardHeader>
								<CardBody>
									<Row>
										<Label sm='2'>Hot Spot ID:</Label>
										<Col sm='7'>
											<FormGroup className={validationStyle}>
												<Input
													name='required'
													type='text'
													onChange={(e) => change(e, 'required', 'length', 51)}
												/>
												{validationStyle === 'has-danger' ? (
													<label className='error'>
														Check your ID, it should be a long string of letters
														and numbers.
													</label>
												) : null}
											</FormGroup>
										</Col>
										<Col className='label-on-right' tag='label' sm='3'>
											<code>required</code>
										</Col>
									</Row>
								</CardBody>
								<CardFooter className='text-center'>
									<Button color='primary' onClick={typeClick}>
										Add Hotspot
									</Button>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col md='12'>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>My Hotspots</CardTitle>
							</CardHeader>
							<CardBody>
								{!dataLoading && value ? (
									<ReactTable columns={columns} data={value} />
								) : (
									<PulseLoader
										color={'primary'}
										loading={dataLoading}
										size={150}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default AddHotspot;
