/*eslint-disable*/
import React from 'react';
import {
	useTable,
	useFilters,
	useAsyncDebounce,
	useSortBy,
	usePagination,
} from 'react-table';
import classnames from 'classnames';
// A great library for fuzzy filtering/sorting items
import matchSorter from 'match-sorter';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';

// reactstrap components
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';

// Define a default UI for filtering
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;

	return (
		<FormGroup>
			<Input
				placeholder={`Search ${count} records...`}
				type='text'
				onChange={(e) => {
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
			/>
		</FormGroup>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
	const [numberOfRows, setNumberOfRows] = React.useState({
		value: 30,
		label: '30 rows',
	});
	const [pageSelect, handlePageSelect] = React.useState({
		value: 0,
		label: 'Page 1',
	});
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		state,
		visibleColumns,
		nextPage,
		pageOptions,
		pageCount,
		previousPage,
		canPreviousPage,
		canNextPage,
		setPageSize,
		gotoPage,
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: { pageSize: 30, pageIndex: 0 },
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination
	);

	// We don't want to render all of the rows for this example, so cap
	// it for this use case
	// const firstPageRows = rows.slice(0, 10);
	let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
		function () {}
	);
	let numberOfRowsData = [5, 10, 20, 25, 50, 100];
	return (
		<>
			<div className='ReactTable -striped -highlight primary-pagination'>
				<table {...getTableProps()} className='rt-table'>
					<thead className='rt-thead -header'>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
								{headerGroup.headers.map((column, key) => (
									<th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={classnames('rt-th rt-resizable-header', {
											'-cursor-pointer': headerGroup.headers.length - 1 !== key,
											'-sort-asc': column.isSorted && !column.isSortedDesc,
											'-sort-desc': column.isSorted && column.isSortedDesc,
										})}
									>
										<div className='rt-resizable-header-content'>
											{column.render('Header')}
										</div>
										{/* Render the columns filter UI */}
										<div>
											{headerGroup.headers.length - 1 === key
												? null
												: column.canFilter
												? column.render('Filter')
												: null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()} className='rt-tbody'>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={classnames(
										'rt-tr',
										{ ' -odd': i % 2 === 0 },
										{ ' -even': i % 2 === 1 }
									)}
								>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()} className='rt-td'>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				{data.length > 30 ? (
					<div className='pagination-bottom'>
						<div className='-pagination'>
							<div className='-previous'>
								<button
									type='button'
									onClick={() => previousPage()}
									disabled={!canPreviousPage}
									className='-btn'
								>
									Previous
								</button>
							</div>
							<div className='-center'>
								<Container>
									<Row className='justify-content-center'>
										<Col md='4' sm='6' xs='12'>
											<Select
												className='react-select primary'
												classNamePrefix='react-select'
												name='pageSelect'
												value={pageSelect}
												onChange={(value) => {
													gotoPage(value.value);
													handlePageSelect(value);
												}}
												options={pageSelectData.map((prop, key) => {
													return {
														value: key,
														label: 'Page ' + (key + 1),
													};
												})}
												placeholder='Choose Page'
											/>
										</Col>
										<Col md='4' sm='6' xs='12'>
											<Select
												className='react-select primary'
												classNamePrefix='react-select'
												name='numberOfRows'
												value={numberOfRows}
												onChange={(value) => {
													setPageSize(value.value);
													setNumberOfRows(value);
												}}
												options={numberOfRowsData.map((prop) => {
													return {
														value: prop,
														label: prop + ' rows',
													};
												})}
												placeholder='Choose Rows'
											/>
										</Col>
									</Row>
								</Container>
							</div>
							<div className='-next'>
								<button
									type='button'
									onClick={() => nextPage()}
									disabled={!canNextPage}
									className='-btn'
								>
									Next
								</button>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

export default Table;
